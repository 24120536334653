<template>
  <layout style="background: #555;" :title="$route.name" :options="options">
    <Filtros />

    <div v-if="card" class="expande-horizontal wrap" style="background: #555;">
      <VisualizacaoEmCard
        v-show="get_notifications.docs.length > 0"
        v-for="payload in get_notifications.docs"
        :key="payload.id"
        :payload="payload"
      />
    </div>

    <div v-else class="expande-horizontal wrap">
      <VisualizacaoEmLista v-show="get_notifications.docs.length > 0" />
    </div>

    <ListagemVazia v-show="get_notifications.docs.length === 0" />
    <Paginacao />
    <ModalView />
  </layout>
</template>

<script>
import Filtros from "../components/Filtros.vue";
import VisualizacaoEmCard from "../components/VisualizacaoEmCard.vue";
import VisualizacaoEmLista from "../components/VisualizacaoEmLista.vue";
import Paginacao from "../components/Paginacao.vue";
import ListagemVazia from "../components/ListagemVazia.vue";
import ModalView from "../components/modalView.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      card: false,
      options: [
        {
          icon: "mdi-plus",
          nome: "Nova notification",
          action: () => this.abre_modal_view_notification()
        },
        {
          icon: "mdi-update",
          nome: "Atualizar",
          action: () => this.listar_notifications()
        },
        {
          icon: "mdi-printer",
          nome: "Imprimir",
          action: () => this.imprimir()
        },
        {
          icon: "mdi-ballot-outline",
          nome: "Visualizar em Lista",
          action: () => (this.card = false)
        },
        {
          icon: "mdi-post",
          nome: "Visualizar em Card",
          action: () => (this.card = true)
        }
      ]
    };
  },
  components: {
    Filtros,
    VisualizacaoEmCard,
    VisualizacaoEmLista,
    Paginacao,
    ListagemVazia,
    ModalView
  },
  computed: {
    ...mapGetters([
      "get_notifications",
      "get_notifications_filtros",
      "getLoggedUser"
    ])
  },
  methods: {
    ...mapActions(["abre_modal_view_notification", "listar_notifications"]),
    imprimir() {
      this.card = false;
      this.$refs.html2Pdf.generatePdf();
    }
  },
  created() {
    this.listar_notifications();
  }
};
</script>
